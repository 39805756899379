// Initial data passed to Elm (should match `Flags` defined in `Shared.elm`)
// https://guide.elm-lang.org/interop/flags.html
var flags = {
	googleClientId: process.env.google_client_id,
	isProduction: process.env.NODE_ENV === "production",
};

// Start our Elm application
var app = Elm.Main.init({ flags: flags });

// Ports go here
// https://guide.elm-lang.org/interop/ports.html

/* Generate high entropy random bytes using the Web Crypto API and
remember them so that they are preserved between redirections. This
allows to protect for XSS & authorization code attacks */
app.ports.genRandomBytes.subscribe(n => {
  var buffer = new Uint8Array(n);
  crypto.getRandomValues(buffer);
  var bytes = Array.from(buffer);
  // localStorage.setItem("bytes", bytes);
  app.ports.randomBytes.send(bytes);
});

app.ports.setStorageValue.subscribe(({ key, value }) => {
	localStorage.setItem(key, value);
});

app.ports.getStorageValue.subscribe(localStorageKey => {
	app.ports.gotStorageValue.send({
		key: localStorageKey,
		value: localStorage.getItem(localStorageKey)
	});
});

app.ports.removeStorageValue.subscribe(keyToRemove => {
	localStorage.removeItem(keyToRemove);
});

app.ports.modifyEmailIframe.subscribe((emailInfo) => {
	// TODO: move the `requestAnimationFrame` logic into Elm
	requestAnimationFrame(() => {
		var iframeId = emailInfo.emailId;

		try {
			var iframeEl = document.getElementById(iframeId);

			if (iframeEl) {
				var body = emailInfo.body;

				// TODO: stop using `document.write`
				// https://developers.google.com/web/updates/2016/08/removing-document-write
				iframeEl.contentWindow.document.open();
				iframeEl.contentWindow.document.write(body);
				iframeEl.contentWindow.document.close();

				var cssRules =
					emailInfo.isPlainText
					? [
						"body {",
						"font-family: Charter, sans-serif;",
						"white-space: pre-wrap;",
						"overflow-wrap: break-word;",
						// To support iOS 12 Safari:
						"word-break: break-word;",
						"}",
					].join("")
					: [
						"body {",
						"font-family: sans-serif;",
						"overflow-wrap: break-word;",
						// To support iOS 12 Safari:
						"word-break: break-word;",
						"}",
						"img {",
						/* make sure images are not overflowing the iframe */
						"height: auto;",
						"max-width: 100%;",
						"object-fit: contain;",
						"}",
					].join("");

				var styles = [
					"<style>",
					cssRules,
					"</style>",
				].join("");

				var iframeResizerContentWindow = document.createElement("script");
				iframeResizerContentWindow.setAttribute("src", "iframeResizer.contentWindow.min.js");
				iframeEl.contentDocument.head.appendChild(iframeResizerContentWindow);
				iFrameResize({ checkOrigin: false, scrolling: true }, iframeEl);

				var additionalHeadHtml = [
					"<base target='_blank' />",
					styles,
					// TODO: don't insert meta viewport if it already exists
					"<meta name='viewport' content='width=device-width, initial-scale=1' />",
				].join("");
				iframeEl.contentDocument.head.insertAdjacentHTML('afterbegin', additionalHeadHtml);
			} else {
				console.error("Not found iframe for id", iframeId);
			}
		} catch(error) {
			console.log("modifyEmailIframe error for id", iframeId);
			console.error(error);
		}
	});
});

app.ports.updateEmailIframeWithAttachment.subscribe((attachmentInfo) => {
	requestAnimationFrame(() => {
		const iframeEl =
			document.getElementById(attachmentInfo.emailId);
		const cssSelector =
			["img[src='cid:", attachmentInfo.contentId, "']"].join("");
		const imgElement =
			iframeEl
			.contentDocument.body
			.querySelector(cssSelector);

		if (imgElement === null) {
			debugger;
			console.error(
				"[port `updateEmailIframeWithAttachment`] img element not found",
				iframeEl,
				cssSelector,
			);
			return;
		}
			
		imgElement.src = [
			"data:",
			attachmentInfo.mimeType,
			";base64,",
			attachmentInfo.data,
		].join("");
	});
});

if (!window.gapi) {
	// TODO: what should the fallback be?
	console.error("gapi is falsy:", window.gapi);
	return;
}

app.ports.renderGoogleSignInButton.subscribe(({ elementId, scope }) => {
	requestAnimationFrame(() => {
		if (window.gapi) {
			console.log("Rendering the sign in button...");
			gapi.signin2.render(
				elementId,
				{
					scope: scope,
					prompt: "select_account",
					longtitle: true,
					theme: "dark",
					onsuccess: (googleUser) => {
						console.log("gapi.signin2.render > onsuccess", googleUser);
						const basicProfile = googleUser.getBasicProfile();
						app.ports.onGoogleSignIn.send({
							email: basicProfile.getEmail(),
							imageUrl: basicProfile.getImageUrl(),
							accessToken: googleUser.getAuthResponse(true).access_token,
						});
					},
					onfailure: (...args) => {
						console.error("gapi.signin2.render > onfailure", args);
					},
				}
			);
		} else {
			console.error("renderGoogleSignInButton error: gapi is falsy:", gapi);
		}
	})
});

app.ports.signOutFromGoogle.subscribe(() => {
	if (window.gapi) {
		gapi.auth2.getAuthInstance().signOut()
		.then(() => {
			app.ports.initializedGoogleAuth.send(null);
		});
	} else {
		console.error("signOutFromGoogle error: gapi is falsy:", gapi);
	}
});

app.ports.getNewAccessToken.subscribe(() => {
	if (window.gapi) {
		gapi.auth2.getAuthInstance()
		.then((googleAuth) => {
			const googleUser = googleAuth.currentUser.get();
			const { access_token, expires_at } =
				googleUser.getAuthResponse(true);

			// TODO: refresh user's info (e.g. profile image and email)
			if (expires_at < Date.now()) {
				googleUser.reloadAuthResponse()
				.then((newAuth) => {
					app.ports.gotNewAccessToken.send(newAuth.access_token);
				});
			} else {
				app.ports.gotNewAccessToken.send(access_token);
			}
		});
	} else {
		console.error("signOutFromGoogle error: gapi is falsy:", gapi);
	}
});

// TODO: if couldn't initialize because of unavailable network, try again on network reconnect
app.ports.initializeGoogleApi.subscribe(() => {
	console.log("[port] initializeGoogleApi");
	gapi.load("auth2", (...args) => {
		console.log("initializedGoogleAuth > load", args);
		try {
			const safariRegex = /Safari\/\S+/;
			const chromeRegex = /Chrom(e|ium)\/\S+/;
			const isSafari =
				safariRegex.test(window.navigator.userAgent)
				&& !chromeRegex.test(window.navigator.userAgent);
			gapi.auth2.init({
				client_id: process.env.google_client_id,
				// Workaround: Safari's "Prevent cross-site tracking" feature breaks the sign-in
				// unless a popup used to sign in
				// More info: https://github.com/google/google-api-javascript-client/issues/342
				// https://github.com/google/google-api-javascript-client/issues/260
				// https://github.com/google/google-api-javascript-client/issues/503
				// https://github.com/google/google-api-javascript-client/issues/589
				ux_mode: isSafari ? "popup" : "redirect",
			})
			.then((auth) => {
				// TODO: use `currentUser.listen` to track changes to the user object
				// https://developers.google.com/identity/sign-in/web/reference#googleauthcurrentuserlistenlistener
				console.log("Auth2 initialized with:", auth);
				app.ports.initializedGoogleAuth.send(null);
			})
			.catch((initError) => {
				console.error("async init error:", initError);
				app.ports.initializedGoogleAuth.send(initError);
			});
		} catch (auth2InitError) {
			console.error("init error:", auth2InitError);
		}
	});
});